@import "~@ng-select/ng-select/themes/default.theme.css";

/* You can add global styles to this file, and also import other style files */

/* Fonts start */
/* Myriad font start*/
@font-face {
  font-family: "Myriad Pro";
  src: url("./assets/fonts/MyriadPro-Bold.eot");
  src: url("./assets/fonts/MyriadPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/MyriadPro-Bold.woff2") format("woff2"),
    url("./assets/fonts/MyriadPro-Bold.woff") format("woff"),
    url("./assets/fonts/MyriadPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("./assets/fonts/MyriadPro-Regular.eot");
  src: url("./assets/fonts/MyriadPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/MyriadPro-Regular.woff2") format("woff2"),
    url("./assets/fonts/MyriadPro-Regular.woff") format("woff"),
    url("./assets/fonts/MyriadPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("./assets/fonts/MyriadPro-Semibold.eot");
  src: url("./assets/fonts/MyriadPro-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/MyriadPro-Semibold.woff2") format("woff2"),
    url("./assets/fonts/MyriadPro-Semibold.woff") format("woff"),
    url("./assets/fonts/MyriadPro-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Myriad Pro";
  src: url("./assets/fonts/MyriadPro-Light.eot");
  src: url("./assets/fonts/MyriadPro-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/MyriadPro-Light.woff2") format("woff2"),
    url("./assets/fonts/MyriadPro-Light.woff") format("woff"),
    url("./assets/fonts/MyriadPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* Myriad font end */
/* Fonts end */
/* Root start */
:root {
  /* default colors */
  --primary-color: #e9e9e9;
  /* --secondary-color: #e3c88e; */
  --white-color: #ffffff;
  --black-color: #000000;
  --text-color: #959595;
  --gradiant-color: linear-gradient(134.63deg, #ff2f2f 4.36%, #c20202 89.37%);
  --danger-color: #fa2b2b;
  --danger-color0: #f41616;
  --plcaholder-color: #ebebeb;
  --blue-color: #000749;
  --green-color: #2ca72a;
  /* Font style */
  --primary-font: "Myriad Pro", sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
}

html {
  height: 100%;
}
/* Root end */
/* default css start */
body {
  font-family: var(--primary-font);
  background-color: var(--primary-color);
  font-size: 14px;
  color: var(--black-color);
  font-weight: 400;
  height: 100%;
}

a {
  color: var(--blue-color);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: 0.3s all;
  text-decoration: none;
}

a:hover {
  color: var(--text-color);
  text-decoration: none;
}

a:focus {
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--primary-font);
  color: var(--black-color);
  font-weight: 700;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: var(--primary-color);
}

body h1:last-child,
body h2:last-child,
body h3:last-child,
body h4:last-child,
body h5:last-child,
body h6:last-child,
body p:last-child {
  margin-bottom: 0;
}

p {
  font-size: 18px;
  color: var(--black-color);
}

.big-heading-title {
  font-weight: bold;
  font-size: 26px;
  color: var(--black-color);
}

.heading-title {
  font-size: 20px;
  font-weight: 700;
  color: var(--black-color);
}

.btn,
button:focus,
.btn:focus,
.form-control:focus,
.form-select:focus {
  outline: none !important;
  box-shadow: none !important;
}

.custom-input:focus {
  background-color: #545353;
  border-color: #545353;
  color: var(--white-color);
}

.form-control:focus::placeholder {
  color: #767676 !important;
}

.custom-input {
  height: 50px;
  background-color: var(--plcaholder-color);
  border: 1px solid var(--plcaholder-color);
  padding: 5px 15px;
  color: var(--black-color);
  font-size: 18px;
  transition: 0.3s all;
}

::selection {
  color: var(--white-color);
  background: var(--danger-color);
}

::placeholder {
  color: #767676 !important;
  font-weight: 400 !important;
}

ul {
  margin: 0px;
  padding: 0px;
}

ul li {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.d-align {
  display: flex;
  align-items: center;
}

.d-align-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.img-cover {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

.hover-txt {
  transition: all 0.3s;
}

.hover-txt:hover {
  color: var(--danger-color) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-btn {
  min-width: 150px;
  min-height: 50px;
  padding: 6px 10px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 0;
  color: var(--white-color);
  border: 1px solid var(--danger-color);
  background-color: var(--danger-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.custom-btn:hover {
  /* background-color: transparent; */
  /* color: var(--danger-color); */
}

.custom-btn:active {
  transform: scale(0.9);
}

.white-btn {
  background: var(--white-color);
  border-color: var(--white-color);
  border: 1px solid var(--primary-color);
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--black-color);
}

.price-btn {
  background: transparent;
  border-color: transparent;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--black-color);
}

.white-btn:hover {
  background-color: var(--blue-color);
  border-color: var(--blue-color);
  /* color: var(--white-color); */
}

.white-btn:focus {
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.13) !important;
}

.green-btn {
  background-color: var(--green-color);
  border-color: var(--green-color);
  color: var(--white-color);
}

.green-btn:hover {
  border-color: var(--green-color);
  color: var(--green-color);
}

.dark-gray-btn {
  background-color: #ebebeb;
  color: var(--black-color);
  font-weight: 600;
  min-height: 42px;
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.dark-gray-btn:hover {
  background-color: transparent;
  border-color: var(--danger-color);
  color: var(--danger-color);
}

.dark-gray-select {
  box-shadow: none;
  background-color: #ebebeb;
}

.z-index-1 {
  z-index: 1;
}

.w-max-content {
  width: max-content;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 5.5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.white-scroll::-webkit-scrollbar-thumb {
  background-color: var(--white-color);
}

.white-scroll::-webkit-scrollbar-track {
  background-color: #ccc;
}

.active-link {
  color: var(--blue-color) !important;
}

/* .main-section-px {
  padding: 35px 93px;
} */
.custom-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--black-color);
  margin-bottom: 10px;
  margin-top: 10px;
}

.box-shadow {
  box-shadow: none !important;
}

.form-group-mb-big {
  margin-bottom: 25px;
}

.gray-color {
  color: #01a325;
}

.blue-color {
  background-color: var(--blue-color);
}

.blue-color-border {
  border-color: var(--blue-color);
}

.blue-color-border:hover {
  /* color: var(--blue-color); */
}

/* default css end */
/* autho-form css start */
.autho-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 50px 12px;
}

.autho-form h2 {
  font-size: 28px;
  text-align: center;
}

.autho-form {
  max-width: 500px;
  min-height: 500px;
  margin: 0 auto;
  background-color: var(--white-color);
  padding: 30px;
  border-radius: 15px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.autho-main-box {
  max-height: calc(100vh - 100px);
  overflow-y: overlay;
  padding: 0 12px;
  padding-bottom: 40px !important;
}

.form-group:not(:last-child) {
  margin-bottom: 25px;
}

.form-label {
  font-size: 16px;
  font-weight: 600;
}

.reg-for-txt {
  font-size: 14px;
  font-weight: 600;
}

.blue-btn {
  background-color: var(--blue-color);
  border: none;
}

.blue-btn:hover {
  background-color: var(--blue-color);
  color: white !important;
}

/* autho-form css end */

/* Validation Warning */
.text-red {
  color: #ff1010;
}

/* Validation Warning */
/* all module css */
.main-wrap {
  padding: 20px 50px 0;
  /* height: 100vh - 100 !important; */
}

.main-wrap-pb {
  padding-bottom: 35px;
}

/* all module css */
/* analytics css start */
header {
  background-color: var(--white-color);
  padding: 10px 42px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  position: sticky;
  top: 0;
  z-index: 9;
}

.menu-list {
  display: flex;
  align-items: center;
  grid-gap: 190px;
  /* padding: 0 0 0 270px; */
  margin: 0 auto;
}

.menu-list li a {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-color);
  transition: all 0.3s;
}

.menu-list li a:hover {
  color: var(--blue-color);
}

.analytics-select-col {
  width: 65%;
}

.analytics-import-export-col {
  width: 35%;
}

.analytics-select-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 36px;
}

.analytics-select-2grid {
  grid-template-columns: repeat(2, 1fr);
}

.form-input {
  box-shadow: 0px 6px 4px rgb(0 0 0 / 13%);
  border-radius: 10px;
  min-height: 42px;
  border-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.analytics-search-bar .search-ico {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.analytics-search-bar input {
  padding-left: 50px;
}

.dark-gray-input {
  box-shadow: none;
  background-color: #ebebeb;
  color: #6f6d6d;
  font-weight: 600;
}

.form-input:hover,
.form-input:focus,
.form-control.load-notes-textarea:hover,
.form-control.load-notes-textarea:focus,
.select2-search__field:focus-visible:hover {
  border-color: var(--danger-color);
}

.analytics-box-section {
  margin-top: 87px;
}

.analytics-box-row {
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 1.84227px 5.52681px 11.9748px rgba(0, 0, 0, 0.2);
}

.analytics-box-col-left {
  width: 530px;
  min-height: 170px;
  height: 100%;
  background-color: var(--blue-color);
  padding: 30px 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analytics-box-col-left h5 {
  font-weight: 700;
  font-size: 30px;
  color: var(--white-color);
  text-align: center;
}

.analytics-box-col-left p {
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  color: var(--white-color);
  text-align: center;
}

.analytics-box-col-right {
  width: calc(100% - 530px);
  min-height: 170px;
  height: 100%;
  background-color: var(--white-color);
  height: 100%;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.analytics-box-lists-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.analytics-box-lists-col:not(:last-child) {
  border-right: 2px solid #c8c8c8;
}

.analytics-box-lists-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 120px;
}

.analytics-box-lists-col h6 {
  font-weight: 600;
  font-size: 26px;
  text-transform: uppercase;
  color: #595959;
}

.analytics-box-lists-col h4 {
  font-weight: 400;
  font-size: 48px;
  color: var(--black-color);
}

/* analytics css end */
/* load-os-dispatch-status-section start */
.load-os-dispatch-status-left-col {
  width: calc(100% - 440px);
  padding: 0 12px;
}

.load-os-dispatch-status-right-col {
  position: relative;
  width: 440px;
}

.load-os-dispatch-status-tbl thead {
  margin-top: 35px;
  display: table;
  width: 100%;
}

.load-os-dispatch-status-tbl {
  width: 100%;
}

.load-os-dispatch-status-tbl thead tr th {
  padding: 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--black-color);
}

.load-os-dispatch-status-tbl thead tr th,
.load-os-dispatch-status-tbl tbody tr td {
  width: 14.28%;
}

/* .custom-table .arrow-data{
  width: 5%;
} */
.load-os-dispatch-status-tbl tbody tr td {
  background: #fbfbfb;
  margin-bottom: 15px;
  padding: 15px;
}

.load-os-dispatch-status-tbl tbody tr td span {
  font-weight: 400;
  font-size: 16px;
  color: var(--black-color);
}

.load-os-dispatch-status-tbl tbody tr td h6 {
  font-weight: 700;
  font-size: 18px;
  color: var(--black-color);
}

.tr-record td:first-child {
  border-radius: 10px 0 0 10px;
}

.tr-record td:last-child {
  border-radius: 0 10px 10px 0;
}

.load-os-dispatch-status-tbl button {
  min-width: 120px;
}

.accordion-table-record {
  /* height: calc(100vh - 280px); */
  padding-right: 10px;
  overflow: overlay;
}

.load-os-dispatch-accordion .accordion-button::after {
  display: none;
}

.accordion-table-record .accordion-item {
  margin-bottom: 15px;
  border-radius: 15px;
  overflow: hidden;
  border: none;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

/* load-os-dispatch-status-section end */
/* collapse CSS */

.collapse .form-style {
  background-color: var(--primary-color);
  box-shadow: none !important;
}

.load-itinerary-box {
  background-color: #ebebeb;
  border-radius: 8px;
  padding: 8px 17px 8px 30px;
}

.load-itinerary-wrap {
  max-height: 315px;
  overflow-y: auto !important;
  padding-right: 10px;
  position: relative;
}

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 29px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 7px;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: var(--white-color);
}

.custom-checkbox input:checked {
  background-color: var(--blue-color) !important;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid var(--black-color);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* collapse CSS */
.load-itinerary-note {
  display: flex;
  align-items: flex-start;
  padding: 0 0 10px 40px;
}

.load-itinerary-list:not(:last-child) .load-itinerary-note {
  border-bottom: 1px solid #d7d7d7;
}

.load-itinerary-note h4 {
  font-weight: 600;
  font-size: 24px;
  color: var(--black-color);
}

.load-itinerary-note h2 {
  font-weight: 700;
  font-size: 24px;
  color: var(--black-color);
  margin-bottom: 0;
}

.load-itinerary-note p {
  font-weight: 400;
  font-size: 16px;
  color: var(--black-color);
}

.stop-btn {
  background-color: var(--white-color) !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 5px 20px 5px 20px;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
}

.add-stop-btn {
  position: sticky;
  bottom: 10px;
  min-width: 100px;
  min-height: 40px;
  font-size: 16px;
  left: 100%;
}

.form-control.load-notes-textarea {
  width: 100%;
  min-height: 185px;
  background: #ebebeb;
  border-color: transparent;
  border-radius: 8px;
}

.load-os-list-map {
  width: 100%;
  height: 240px;
  margin-top: 25px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
}

.side {
  position: relative !important;
}

.big-col {
  width: 100% !important;
}

/* collapse CSS */

.create-load-btn {
  border-radius: 10px;
  min-height: 42px;
  font-size: 16px;
}

/* main form css */
.main-div {
  margin: 0 auto !important;
}

.form-style {
  width: 100% !important;
  height: auto;
  margin: 0 auto !important;
  background-color: var(--white-color);
  padding: 30px;
  border-radius: 15px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  align-items: center !important;
}

.submit-btn {
  background-color: var(--blue-color) !important;
  color: white !important;
  width: 10% !important;
  float: right !important;
  border: none !important;
}

.form-style .custom-input {
  width: 40% !important;
  height: 40px !important;
  border: 1px solid var(--plcaholder-color) !important;
  background-color: transparent !important;
  border-radius: 10px !important;
  padding-left: 20px !important;
  font-size: 16px !important;
}

.form-style .custom-input:focus {
  background-color: #545353;
  border-color: #545353;
  color: #443f3f;
  font-size: 15px !important;
}

.form-style .form-label {
  font-weight: 500;
  padding-left: 50px !important;
  color: #443f3f;
}

.form-check {
  width: 100% !important;
}

.form-check-label {
  color: #443f3f;
  margin-left: 10px !important;
}

.check .col-3 {
  width: auto !important;
  /* margin-right: 4% !important; */
  font-size: 16px !important;
}

.check .col {
  width: auto !important;
  margin-right: 4% !important;
  font-size: 16px !important;
}

.form-check-input:checked {
  background-color: var(--blue-color) !important;
}

.form-style select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99.5%;
  background-position-y: 7px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99.5%;
  background-position-y: 7px;
}

.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='28' viewBox='0 0 28 28' width='28' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99.5%;
  background-position-y: 7px;
}

.form-style option {
  background-color: var(--primary-color);
  font-size: 14px !important;
  height: 40px !important;
  padding: 10px 20px 10px 20px !important;
}

.dropdown-multiselect__caret:before {
  position: relative;
  right: 0;
  top: 65%;
  margin-top: 4px;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  content: "";
}

/* main form css */

/* add load form */

.close-icon {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: var(--blue-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 30px;
  transition: all 0.3s;
}

.close-icon:hover {
  /* background-color: var(--danger-color0); */
}

.load-listing-sidenav {
  background-color: #fff;
  height: 100vh;
  overflow: auto;
  box-shadow: 0px 2px 17px rgb(0 0 0 / 15%);
  margin-top: 0;
  padding: 30px 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

.link-lbl {
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  text-decoration-line: underline;
  color: #7b61ff;
  cursor: pointer;
  transition: all 0.3s;
}

.link-lbl:hover {
  color: var(--black-color);
}

.link-lbl-end {
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  text-decoration-line: underline;
  color: #7b61ff;
  cursor: pointer;
  float: right;
  transition: all 0.3s;
}

.link-lbl-end:hover {
  color: var(--black-color);
}

.load-listing-sidenav .load-notes-textarea {
  min-height: 100px;
}

.load-listing-sidenav-btn .form-label {
  font-size: 14px;
}

.load-listing-btn {
  width: 100%;
  min-height: 120px;
  height: 100%;
  background: var(--white-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid transparent;
  transition: all 0.3s;
}

.load-listing-btn:hover {
  border-color: var(--blue-color);
}

.load-listing-btn img {
  max-width: 40px;
}

.load-listing-sidenav .form-title {
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: var(--black-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-listing-sidenav-head {
  border-bottom: 2px solid #c4c4c4;
  padding-bottom: 30px;
}

.truck-btn {
  /* padding: 15px 45px 15px 45px !important;
  float: right !important;
  font-size: 30px !important; */
  padding: 5px 25px 5px 25px;
  float: right;
  font-size: 18px;
}

/* load-listing-sidenav-body */
.load-listing-sidenav-body {
  padding: 30px;
  height: calc(100vh - 98px);
  overflow-y: scroll !important;
}

.load-listing-title-label {
  font-weight: 600;
  font-size: 20px;
  text-decoration-line: underline;
  /* color: #061d47; */
}

/* add load form */
/* chat board css start */
.load-os-list-chat-pills {
  padding: 10px 15px;
  padding-top: 0px !important;
  justify-content: space-between;
  padding-bottom: 0px;
  padding-left: 21%;
  padding-right: 15%;
  margin-right: 3px;
}

.chat-tab-ico path {
  fill: #b5b4b4;
}

.load-os-list-chat-pills .nav-link.active,
.load-os-list-chat-pills .show > .nav-link {
  background-color: transparent;
  color: #ffffff;
}

.load-os-list-chat-pills .nav-link.active path,
.load-os-list-chat-pills .show > .nav-link path {
  fill: #ffffff;
}

.load-os-list-chat-pills .chat-ico {
  position: relative;
  top: 2px;
}

.active-highlight {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: var(--danger-color0);
  box-shadow: 0 4px 4px #00000040;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #fff;
  padding-left: 3px;
  font-weight: 600;
}

.chat-head {
  padding-bottom: 5px;
  padding-top: 6px;
  margin-left: 15px;
  width: 95%;
  border-radius: 10px;
}

.chat-head span {
  font-weight: 400;
  font-size: 18px;
  color: var(--black-color);
}

.chat-body-wrap {
  padding: 0 10px;
}

.chat-body-content {
  height: calc(100vh - 581px);
  overflow-y: auto;
  overflow-x: hidden !important;
  padding: 0px 15px 15px 0;
}

.chat-body-content::-webkit-scrollbar {
  width: 0 !important;
}

.chat-msg-body-content {
  height: calc(100vh - 620px);
}

.chat-footer-ico {
  width: 35px;
  height: 35px;
  background-color: var(--blue-color);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}

.chat-footer-ico svg {
  width: 18px;
  height: 18px;
}

/* .chat-footer-ico:hover {
  background-color: var(--danger-color0);
} */

.chat-footer-msg-type input {
  border-radius: 30px;
  padding: 5px 22px;
  margin-left: 10px;
}

.chat-body-footer {
  /* padding: 5px 0 10px; */
  margin: 30px 20px 0px 20px;
}

.left-side-msg .msg-note {
  padding: 6px 15px;
  width: max-content;
  max-width: 100%;
  background-color: #e1e1e1;
  border-radius: 25px;
  margin-top: 10px;
}

.left-side-msg span {
  font-weight: 400;
  font-size: 16px;
  color: #383838;
  text-align: left;
}

.right-side-msg {
  display: flex;
  justify-content: end;
}

.right-side-msg .msg-note {
  padding: 6px 15px;
  width: max-content;
  max-width: 100%;
  background-color: var(--black-color);
  border-radius: 25px;
  margin-top: 10px;
  text-align: right;
}

.right-side-msg span {
  font-weight: 400;
  font-size: 16px;
  color: var(--white-color);
  text-align: right;
}

.scheduling-accordion .accordion-button {
  padding: 10px;
}

.scheduling-accordion .accordion-item {
  margin-bottom: 10px;
  border: 1px solid #d6d6d6;
  background-color: #f7f7f7;
  border-radius: 7px;
  overflow: hidden;
}

.scheduling-accordion .accordion-button span {
  font-weight: 400;
  font-size: 16px;
  color: var(--black-color);
}

.accordion-button::after {
  transform: rotate(-90deg);
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(0deg);
}

.acc-body-head {
  font-weight: lighter;
  font-size: 24px;
  color: var(--black-color);
}

.price-available-list p {
  font-weight: 400;
  font-size: 12px;
  color: var(--black-color);
  letter-spacing: 0.2px;
}

.book-now-price-txt h2 {
  font-weight: 700;
  font-size: 24px;
  color: var(--black-color);
}

.find-dismiss-btn .custom-btn {
  min-width: 100px;
  min-height: 35px;
  font-size: 14px;
}

.light-blue-btn {
  background-color: #0d6799;
  border-color: #0d6799;
}

.gray-btn {
  background-color: #727272;
  border-color: #727272;
}

.find-dismiss-btn .custom-btn:hover {
  border-color: var(--blue-color);
  color: var(--blue-color);
}

.book-now-price-txt .custom-btn {
  min-width: 130px;
  min-height: 40px;
}

/* chat board css end */

/* Add Stop from add load form  css starts*/
.add-stop-form {
  background-color: var(--white-color);
  margin-top: 20px !important;
  padding: 20px !important;
  border-radius: 10px !important;
}

.add-stop-form .close-icon {
  margin-left: 95% !important;
}

.light-font {
  font-weight: 400;
  font-size: 16px;
  color: var(--black-color);
}

.add-stop-title {
  margin-bottom: 20px !important;
}

.stop-table {
  font-size: 18px !important;
  margin: 10px !important;
  border: 1px solid var(--primary-color);
}

.stop-table th {
  background-color: var(--primary-color);
  padding: 5px;
  color: var(--text-color);
}

.stop-table th:not(:first-child) {
  border-left: 1px solid var(--white-color);
}

.stop-table td {
  padding: 5px;
}

.stop-table td:not(:first-child) {
  border-left: 1px solid var(--text-color);
}

.edit-icon {
  height: 30px;
  width: 30px;
}

.add-stop-form .btn {
  width: 100px !important;
  margin-left: 90%;
  background-color: var(--blue-color) !important;
  color: white;
  border-color: transparent !important;
}

.closeBTN {
  background-color: #000749;
  border: none;
  color: #ffffff !important;
  font-size: 25px !important;
}

.notes .form-label {
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}

.notes textarea {
  height: 150px !important;
  border-color: #c5bebe;
  border-radius: 8px !important;
}

.attension textarea {
  height: 120px !important;
  border-color: #c5bebe;
  border-radius: 8px !important;
}

.modal .right .form {
  padding-top: 0px !important;
}

#add_pickUp-Stop .modal-body {
  margin: 30px !important;
}

#add_pickUp-Stop .site-drop .link {
  font-size: 120%;
  margin-top: 20px !important;
}

#add_pickUp-Stop .btn {
  background-color: blue !important;
  border-color: blue;
}

#add_pickUp-Stop .btn:hover {
  background-color: blue !important;
  border-color: blue;
  color: white;
}

/* Add Stop from add load form  css end*/

/*  user menu dropdown css start */
.dropbtn {
  font-size: 16px;
  border: none;
  background-color: transparent;
  color: white !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: fit-content;
}

.dropdown-content a {
  color: #959595;
  padding: 5px 15px 5px 15px;
  text-decoration: none;
  display: block;
  font-weight: 600;
  font-size: 16px;
  /* padding-left: 42px; */
  text-align: center;
}

.dropdown-content a:hover {
  background-color: #ddd;
}
.menu-list .active {
  font-weight: 600;
}

.dropdown:hover .dropdown-content {
  display: block;
  z-index: 1;
}

/*  user menu dropdown css end */

/* customer page css start */
.customer {
  padding: 18px !important;
}

.customer-table tbody {
  overflow-y: auto !important;
}

.customer-table tbody {
  background-color: white;
}

.customer-table thead th {
  padding: 10px 10px 10px 10px !important;
  font-size: 20px !important;
  font-weight: 600;
}

.customer-table thead th:not(:first-child) {
  text-align: center;
}

.customer-table tbody td:not(:first-child) {
  text-align: center;
}

.customer-table tbody tr:not(:last-child) {
  border-bottom: 1px solid var(--primary-color);
}

.customer-table tbody td {
  font-size: 15px;
  padding: 18px;
}

.pagination-btn {
  border-radius: 0px !important;
  width: 20px !important;
  padding: 0px !important;
}

.pagination .col {
  width: 20px !important;
}

.pagination {
  padding-top: 20px;
}

.pagination .custom-btn {
  min-width: 20px !important;
}

.customer-table a {
  color: var(--blue-color);
  text-decoration: underline;
  font-size: 13px !important;
}

table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow: auto !important;
}

/* customer page css end */
/* customer form */

.customer-form .modal-header {
  background-color: var(--text-color);
}

.customer-form .closeBTN {
  background-color: var(--text-color);
}

.customer-form .form-label {
  color: var(--text-color);
}

.customer-form-btn {
  background-color: #000749;
  border: none;
  float: right !important;
}

.customer-form-btn:hover {
  background-color: var(--primary-color);
  color: var(--text-color);
}

/* customer form */

/* Assets screen */
.sub-section {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  padding-right: 4%;
}

.assets-table-form-data-wrap table {
  border: 1px solid var(--text-color);
}

.assets-table-form-data-wrap th {
  padding: 10px;
  font-size: 15px;
  background-color: var(--text-color);
}

.assets-table-form-data-wrap td {
  padding: 10px;
}

.assets-table-form-data-wrap tbody tr:not(:last-child) {
  border-bottom: 1px solid var(--text-color);
}

.link-edit {
  text-decoration: underline;
  color: #000749;
  cursor: pointer;
}

.assets-table-form table {
  width: 100%;
}

tr {
  transition: all 0.3s;
}

tr:hover {
  background-color: #e1e1e1;
}

/* Assets screen */

/* User form */
/* 
#add_user input {
  border: none !important;
}
#add_user select {
  border: 1px solid var(--primary-color);
} */
.add-user-sub-title {
  font-weight: bold !important;
  font-size: 600;
  padding-top: 0 !important;
}

/* #add_user input .th {
  font-weight: bold !important;
  font-size: 600;
  padding-top: 0 !important;
} */
.date-input {
  box-shadow: none !important;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color) !important;
  border-radius: 10px 0px 0px 10px;
  height: 42px;
}

.cal-icon {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  box-shadow: none;
  border-radius: 0px 10px 10px 0px;
  height: 42px;
  padding-left: 1px;
  padding-right: 5px;
}

.right-radius {
  border-radius: 10px 0px 0px 10px;
}

.left-radius {
  border-radius: 0px 10px 10px 0px;
}

/* User form */
/* schedule-section start */
.schedule-section {
  background-color: var(--white-color);
  padding: 20px 50px;
}

.schedule-form-search {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px 50px;
}

.schedule-form-col {
  width: 200px;
}

.schedule-form-search .create-load-btn {
  background-color: #ebebeb;
  border-color: #ebebeb;
}

/* schedule-section end */

/* comman modal css starts */
.modal-header {
  background-color: #000749 !important;
  color: #ffffff !important;
}

.btn-close {
  color: #ffffff;
}

.edit-record {
  color: #000749;
  text-decoration: underline;
  font-size: 13px;
}

/* comman modal css end */

/* scheduling page css start */
.suggest-loads-switch {
  display: flex;
  align-items: center;
  margin-left: auto;
  width: max-content;
}

.custom-switch .form-check-input {
  margin: 0;
  width: 60px;
  height: 27px;
  margin-left: 15px;
  cursor: pointer;
  border: 1px solid var(--text-color);
}

.right-arrow {
  font-size: 40px;
  color: #a7a7a7;
  font-weight: 700;
}

.custom-switch .form-check-input:checked {
  background-color: var(--green-color) !important;
  border-color: var(--green-color) !important;
}

.schedule-table td:not(:first-child) {
  /* padding-left: 7% !important; */
  /* width: 10% !important;
  text-align: center !important; */
}

.schedule-table td {
  /* padding-left: 7% !important; */
  width: 12% !important;
}

.schedule-table th {
  /* padding-left: 7% !important; */
  width: 12% !important;
}

.schedule-loads {
  padding: 20px 50px 0;
}

.schedule-loads-tbl thead tr th,
.schedule-loads-tbl tbody tr td {
  width: 20%;
}

.tbl-res-pr {
  padding-right: 10px;
}

.schedule-loads-accordion {
  height: auto;
}

.schedule-loads-accordion .accordion-button::after {
  position: absolute;
  right: 5px;
  /* display: none !important; */
}

.schedule-loads-noumber {
  width: 30px;
  height: 30px;
  background-color: #808080;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--white-color);
  font-weight: 700;
}

.schedule-loads-body-content {
  background: var(--white-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 9px;
  padding: 15px;
}

.schedule-loads-body-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 100px;
  align-items: center;
}

.schedule-loads-accordion .accordion-body {
  background-color: var(--primary-color);
}

.schedule-loads-inner-addprice h2 {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: var(--black-color);
}

.schedule-loads-inner-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.schedule-loads-inner-body {
  background: var(--white-color);
  border-radius: 9px;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 65px;
}

.schedule-loads-like-ico:hover {
  fill: var(--danger-color0);
}

.suggest-loads-switch {
  padding-right: 20px;
}

.schedule-loads-datepicker-box {
  background-color: var(--white-color);
  width: 100%;
  min-height: 400px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
  border-radius: 42px;
}

.schedule-loads-datepicker-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  align-items: center;
  justify-content: center;
}

.schedule-date-list-dot {
  width: 15px;
  height: 15px;
  background-color: #ccc;
  border-radius: 100%;
}

.schedule-loads-datepicker-list span {
  font-weight: 600;
  font-size: 14px;
  color: var(--black-color);
}

.green-dot {
  background-color: var(--green-color);
}

.gray-dot {
  background-color: #a9a7a7;
}

.red-dot {
  background-color: #ff5656;
}

/* scheduling page css end */
/* calender start */
.calender-div {
  margin-left: 5%;
  height: 400px;
  width: 488px;
  background-color: white;
}

.dots {
  margin-left: 5%;
}

/* calender ends */

.sm-btn .col-md-4 {
  width: 15%;
  bottom: 0 !important;
}

.mid-btn {
  width: 50%;
}

.addr-div {
  padding-top: 20px;
}

.stop-row {
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 10px;
  padding-top: 10px;
}

.add-remove .col-3 {
  width: 7%;
}

.active {
  color: var(--blue-color) !important;
}

/* ng-select2 css start */

.select2-container .select2-selection--single {
  height: 40px !important;
  border: 1px solid var(--plcaholder-color) !important;
  background-color: transparent !important;
  border-radius: 10px !important;
  padding-left: 20px !important;
  font-size: 16px !important;
  padding-top: 5px !important;
  width: 100% !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 5px !important;
  right: 5px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: var(--black-color) !important;
  color: #6f6d6d !important;
}

/* ng-select2 css end */

.map-page {
  padding: 30px !important;
}

/* calender css start */
.fc-view-harness:hover {
  background-color: white !important;
}

.fc-view-harness-active:hover {
  background-color: white !important;
}

.fc .fc-scrollgrid-section:hover {
  background-color: white !important;
}

.fc-scrollgrid-sync-table tr:hover {
  background-color: white !important;
}

.full-cal {
  margin: 10px !important;
  overflow: hidden !important;
}

.fc-scrollgrid-liquid thead tr {
  font-size: 18px !important;
}

.fc-scrollgrid {
  border: none !important;
}

.fc-theme-standard th {
  border: none !important;
}

.fc-theme-standard td {
  border: none !important;
  font-size: 18px !important;
}

.fc td {
  margin-right: 10px !important;
}

.fc .fc-toolbar-title {
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  font-weight: 600 !important;
}

.fc-today-button {
  display: none !important;
}

.fc-icon {
  color: #000000 !important;
}

.fc .fc-button-primary {
  background-color: white !important;
  border: none !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0px !important;
}

.fc .fc-daygrid-day-number {
  padding: 0px !important;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 0px !important;
}

.fc .fc-daygrid-day-number {
  padding-right: 20px !important;
  padding-top: 5px !important;
  color: var(--black-color) !important;
}

.fc a {
  color: var(--black-color) !important;
}

.fc-day-today {
  background-color: inherit !important;
}

.fc-prev-button {
  /* display: none !important; */
}

/* calender css end */
/* change design related view start */
.body-wrap-detail-box {
  background-color: var(--white-color);
  width: 100%;
  box-shadow: 0px 2px 16px rgb(0 0 0 / 15%);
  border-radius: 42px;
  padding: 50px;
}

.acc-setting-wrap .form-label {
  width: 100%;
  max-width: 250px;
  margin-bottom: 0;
}

.acc-setting-wrap .form-input-col {
  width: 100%;
  max-width: 600px;
  /* padding: 0 12px; */
}

.acc-setting-main-col:not(:last-child) {
  margin-bottom: 30px;
}

.select2-container--default .select2-selection--single {
  background-color: #ebebeb !important;
  color: #6f6d6d;
  font-weight: 600;
}

.select2-dropdown {
  border: 1px solid #aaa !important;
  margin-top: 5px;
}

.select2-search__field:focus-visible:focus-visible {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--danger-color) !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0 !important;
}

.create-acc-form-wrap {
  max-width: 1000px;
  margin: 0 auto;
}

.stng-title {
  margin-bottom: 30px;
}

/* change design related view end */

/* Docs & Charges start */

.docs-footer {
  width: fit-content !important;
  position: fixed !important;
  bottom: 0 !important;
  padding: 10px !important;
  margin-right: 0px !important;
  /* box-shadow: 0px 10px 5px rgb(0 0 0 / 13%) inset; */
}

.docs-footer .load-listing-btn {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  margin-top: 5px !important;
}

.docs-footer .load-listing-btn:hover {
  border: none !important;
}

.docs-footer .row {
  margin-left: 10% !important;
  margin-right: 0px !important;
}

.docs-footer .col {
  width: 25% !important;
}

.doc-check {
  padding: 30px !important;
}

.doc-check .col-3 {
  width: 8% !important;
}

.doc-check .big-f {
  font-weight: 550;
  font-size: 18px;
}

.doc-check .sml-lbl {
  font-weight: 400;
  font-size: 13px;
}

.doc-check .row {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary-color);
}

.doc-check .form-check-input {
  margin-top: 20px !important;
}

.dollar {
  font-weight: 400;
  font-size: 16px;
  background-color: #e1e1e1;
  padding: 5px 15px 5px 15px !important;
  border-radius: 8px;
  margin-top: 15px !important;
}

.img-down {
  height: 20px;
  width: 20px;
  margin-left: 20px;
}

/* Docs & Charges ends */

/* dashboard css starts */
.blu-bg {
  background-color: var(--blue-color);
  color: var(--primary-color);
}

.logo-col {
  width: 8% !important;
  border: 10px solid var(--blue-color) !important;
  background-image: url("./assets/images/icons/db1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}

.white-gb {
  background-color: var(--white-color);
}

.dash-statistic .dash-row {
  margin: 30px 0px 0px 0px;
  border-radius: 10px !important;
  box-shadow: 0px 6px 4px rgb(0 0 0 / 13%);
}

.dash-col {
  padding: 10px !important;
  border-left: 1px solid var(--text-color);
}

.company-title {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.dash-small {
  font-size: 15px;
  display: flex;
  justify-content: center;
}

.dash-small-gray {
  font-size: 18px;
  color: #595959;
  display: flex;
  justify-content: center;
}

.dash-img {
  height: 100%;
  width: 100%;
}

.statistic-no {
  font-size: 30px !important;
  display: flex;
  justify-content: center;
}

.company-detail {
  padding-left: 10%;
}

.card-title {
  font-weight: 550;
  font-size: 17px;
}

.card-button {
  border: none;
  float: right !important;
  padding: 5px 10px 5px 10px;
}

.dash-card-header {
  background-color: var(--white-color);
  border: none !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.radius-left {
  border-radius: 10px 0px 0px 10px !important;
}

.radius-right {
  border-radius: 0px 10px 10px 0px !important;
}

.biggest-lbl {
  background-color: var(--blue-color);
  border-radius: 50px;
  border: 20px solid var(--white-color);
  padding: 30px;
  font-size: 50px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  color: var(--white-color);
}

.card {
  box-shadow: 0px 6px 4px rgb(0 0 0 / 13%) !important;
  border-radius: 10px;
  padding: 15px;
}

.card-botton a {
  float: right !important;
  padding-right: 10px !important;
  color: var(--blue-color) !important;
  font-size: 12px;
  cursor: pointer;
}

.inner-card {
  box-shadow: 0px 6px 4px rgb(0 0 0 / 13%) !important;
  border-radius: 10px;
  margin: 20px;
  width: -webkit-fill-available !important;
  padding: 10px;
  height: 220px;
  overflow: scroll;
  padding-right: 10px !important;
}

.td-30 {
  width: 20% !important;
  padding-left: 0.5rem;
}

.dash-modal .modal-header {
  background-color: var(--white-color) !important;
}

.dash-modal .modal-title {
  margin-left: 42%;
}

.no-shadow {
  box-shadow: none !important;
  border: 1px solid var(--text-color);
  min-height: 20px !important;
}

.dash-modal .modal-body {
  padding: 30px !important;
}

input:read-only {
  background-color: white !important;
  border: 1px solid var(--text-color);
}

.dash-modal-btn {
  font-size: 15px !important;
  margin-top: 20px !important;
  min-height: 30px !important;
}

.dash-modal .cal-icon {
  box-shadow: none !important;
}

.dash-modal .modal-title {
  font-size: 30px !important;
  font-weight: 450;
}

.dash-route {
  padding: 0px 0px 30px 10px;
}

.dash-tbl .accordion-table-record {
  padding: 10px !important;
  background-color: var(--primary-color) !important;
  overflow: scroll !important;
}

.dash-tbl .custom-table {
  padding: 10px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.dash-tbl .accordion-item {
  border-radius: 7px !important;
}

.dash-tbl .load-os-dispatch-status-tbl tbody tr td {
  width: 10% !important;
}

.dash-tbl .custom-table thead tr th {
  width: 10% !important;
}

.map-box-wrap {
  height: calc(100vh - 157px);
}

.load-itinerary-content {
  padding-left: 15px;
}

.doc-charges-list {
  margin-top: 50px;
  height: calc(100vh - 355px);
  overflow-y: auto;
}

.doc-charges-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #eeeeee;
  padding: 15px;
}

.doc-charges-list .form-input {
  width: 120px;
}

.doc-charges-list h4 {
  font-weight: 400;
  font-size: 16px;
  color: var(--black-color);
  margin-bottom: 0;
}

.doc-charges-list p {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.doc-charges-list .custom-checkbox .checkmark {
  border: 1px solid #666666;
}

.doc-charges-list .load-itinerary-content {
  /* max-width: 130px; */
  width: 100%;
  padding-left: 15px;
}

.doc-charges-list .custom-checkbox {
  margin-bottom: 30px;
}

.doc-charges-footer {
  width: 100%;
  height: 130px;
  background-color: var(--white-color);
  box-shadow: 0px -3px 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 11% !important;
}

.min-dash {
  /* margin-left: 30px; */
}
.no-display {
  display: none !important;
}
/* .ng-input > input {
  display: none;
} */
@media (max-width: 1190px) {
  .menu-list {
    grid-gap: 150px;
  }

  .maximize-pane .col-md-8 {
    width: 45%;
  }

  .maximize-pane .col-md-1 {
    padding-left: 120px;
  }

  .load-os-list-chat-pills {
    padding-left: 35%;
    padding-right: 10%;
  }

  .chat-head {
    padding-left: 0px;
  }
}

/* dashboard css ends */
@media (max-width: 1799px) {
  .menu-list {
    grid-gap: 150px;
  }
}

@media (max-width: 1439px) {
  .menu-list {
    grid-gap: 100px;
  }
}

@media (max-width: 1439px) {
  .menu-list {
    grid-gap: 70px;
  }
}

@media (max-width: 1199px) {
  .acc-setting-wrap .form-input-col {
    max-width: 500px;
  }
}

@media (max-width: 991px) {
  .menu-list {
    grid-gap: 50px;
  }

  .menu-list li a {
    font-size: 16px;
  }

  header {
    padding: 15px;
  }

  .acc-setting-wrap .form-label {
    width: 150px;
  }

  .acc-setting-wrap .form-input-col {
    max-width: 400px;
  }

  .modal-dialog {
    max-width: 95%;
  }
}

@media (max-width: 767px) {
  .menu-list {
    grid-gap: 30px;
  }

  .menu-list {
    margin: 0 0 0 auto;
  }

  .main-wrap {
    padding: 30px 30px 0;
  }
}

@media (max-width: 575px) {
  .autho-form h2 {
    font-size: 24px;
  }

  .autho-section {
    padding: 50px 0;
  }

  .autho-form {
    padding: 30px 20px;
  }

  .main-wrap {
    padding: 20px 20px 0;
  }

  .body-wrap-detail-box {
    border-radius: 25px;
  }
}

.dropdown-content {
  z-index: 1 !important;
}

.upload-doc-btn {
  box-shadow: none !important;
  border: none !important;
  height: 51px !important;
  width: auto !important;
  font-size: 18px;
  font-weight: 400;
}

/* filter css */
.filter-date input[_ngcontent-wnb-c173]:read-only {
  background-color: var(--white-color);
}

.filter-date .input-group-text .form-control .cal-icon {
  background-color: var(--primary-color) !important;
  border: none !important;
}

input:read-only {
  background-color: var(--primary-color) !important;
  border: transparent;
}

.filter-dat .cal-icon {
  background-color: var(--primary-color) !important;
  border: none !important;
}

select option {
  z-index: 1 !important;
}

select option:hover {
  background-color: var(--blue-color) !important;
  color: white;
}

.filter select {
  box-shadow: 0px 6px 4px rgb(0 0 0 / 13%) !important;
}

#fil .select2-container .select2-selection--single {
  background-color: var(--white-color) !important;
  box-shadow: 0px 6px 4px rgb(0 0 0 / 13%) !important;
  border: 10px !important;
}

/* filter css */

.remove {
  height: 20px !important;
  width: 20px !important;
}

.add {
  height: 25px !important;
  width: 25px !important;
  margin-left: 10px !important;
}

.chrg-amount {
  background-color: var(--primary-color);
  padding: 10px !important;
  padding-right: 25px !important;
  /* padding-left: 25px !important; */
  font-size: 18px;
  font-weight: 400;
  border-radius: 8px !important;
  /* margin-right: 40px !important; */
}

.app-dt .ngb-tp-input {
  background-color: var(--primary-color) !important;
  border-radius: 10px !important;
  border-color: transparent !important;
  height: 42px !important;
  width: 130% !important;
  font-size: 1rem;
  color: #6f6d6d;
  font-weight: 600;
}

.autocomplete-container {
  background-color: var(--primary-color) !important;
  border-radius: 10px !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.autocomplete-container .not-found {
  border: none !important;
}
.autocomplete-container .suggestions-container ul li {
  height: 28px;
}

.autocomplete-container .suggestions-container ul li a:hover {
  background-color: var(--blue-color);
  color: white;
}

.autocomplete-container .suggestions-container ul li a {
  padding: 5px !important;
  padding-left: 15px !important;
}

.autocomplete-container .suggestions-container {
  border-radius: 10px;
}

.remove-stop {
  color: #ff2d2d !important;
  -webkit-transform: scaleX(0.5);
  transform: scaleX(2);
  font-size: 50px !important;
  cursor: pointer;
}

.add-stop {
  color: #000749 !important;
  font-size: 50px !important;
  cursor: pointer;
}

/* REMOVE invalid/validation icon from input field */
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

/* .form-select:invalid {
  background-image: none !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
} */

/* NEW assets start */
.assets-title {
  font-weight: 550;
  font-size: 24px;
  line-height: 28.13px;
  color: #595959 !important;
  margin-bottom: 20px;
}

.assets-check {
  background-color: var(--white-color);
  border-color: 1px solid black;
}

.assets-check:checked {
  background-color: var(--blue-color) !important;
}

.new-assets .modal-title {
  font-weight: 700 !important;
  line-height: 42.19px !important;
  font-size: 30px;
  color: #100000 !important;
  padding-right: 38% !important;
  font-weight: 500 !important;
  font-size: 25px;
}

.new-assets .modal-header {
  background-color: var(--white-color) !important;
}

.new-assets .closeBTN {
  border-radius: 50px;
  height: 40px;
  width: 40px;
}

.modal-body .title {
  color: #000000 !important;
  font-weight: 600 !important;
  line-height: 40px !important;
  font-size: 25px;
  margin-bottom: 20px !important;
}

.modal-body .asst {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
}

.asst .in-radio {
  margin-left: 20px !important;
}

.asst label {
  margin-left: 5px !important;
}

.modal-body .row {
  margin-bottom: 20px !important;
}

.drag-drop {
  height: 20px !important;
  width: 20px !important;
}

.modal-body button {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

/* NEW assets ends */

.ng-autocomplete {
  width: 100%;
  max-width: 600px;
  display: table;
  margin: 0 auto;
  background-color: var(--primary-color) !important;
  border-radius: 10px !important;
}

.autocomplete-container .suggestions-container {
  margin-top: 1px;
}

.autocomplete-container .input-container input {
  border-radius: 10px !important;
}

.autocomplete-container .suggestions-container ul li a {
  padding: 10px 15px;
}

.autocomplete-container .input-container .x i {
  display: none;
}

::ng-deep .autocomplete-container .input-container input {
  background-color: var(--primary-color) !important;
  border-radius: 10px !important;
}

.autocomplete-container {
  box-shadow: 0 0 0 transparent, 0 0 0 transparent, 0 0 0 transparent;
  border-radius: 10px !important;
  border-color: transparent !important;
}

.bldr {
  font-weight: bolder;
}

/* import file  button */

.input-group-addon {
  background-color: #23272b !important;
  border: 1px solid #2e3439;
  box-shadow: none;
}

.custom-btn > input {
  display: none !important;
}

.image-upload > input {
  display: none;
}

.chat-doc > input {
  display: none;
}

.image-upload label {
  font-size: 16px;
}

.image-upload {
  border-radius: 10px;
  min-height: 42px;
  font-size: 16px;
  background: var(--white-color);
  border-color: var(--white-color);
  box-shadow: 0px 6px 4px rgb(0 0 0 / 13%);
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--black-color);
}

/* import file  button */

.ngb-tp-spacer {
  display: none !important;
}

.app-dt {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (min-width: 992px) {
  .app-dt .col-lg-4 {
    max-width: 47% !important;
    padding: 0px 12px !important;
  }
}

.cust-form .row {
  margin-bottom: 10px !important;
}

.light-title {
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: var(--black-color);
  margin-left: 40%;
  margin-right: 43%;
  /* margin-top: 9px; */
}

.light-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 2px solid #dee2e6;
  background-color: var(--white-color) !important;
}

.stts-btn:focus {
  border: none !important;
}

.load-os-list-chat {
  background-color: #fff;
  height: calc(100vh - 480px);
  box-shadow: 0px 2px 17px rgb(0 0 0 / 15%);
  border-radius: 20px 20px 0 0;
  margin-top: 25px;
}

.maximize-pane {
  margin-top: 98%;
  border-radius: 20px 20px 0 0;
  background-color: #fff;
  bottom: 0 !important;
  padding: 10px !important;
  position: absolute;
  padding-top: 0px !important;
}

.minimize-pane {
  margin-top: 98%;
  border-radius: 20px 20px 0 0;
  background-color: #fff;
  bottom: 0 !important;
  padding: 10px !important;
  width: 100% !important;
  position: absolute;
  right: 0;
}

.maximize-pane::-webkit-scrollbar {
  width: 0 !important;
}

.maximize-pane label {
  font-weight: 600;
  font-size: 18px;
  color: var(--text-color);
  margin-top: 5px !important;
}

.maximize-pane .float-right {
  font-size: 30px !important;
  font-weight: 800 !important;
  margin-top: 0px !important;
  margin-bottom: 25px !important;
}

.maximize {
  margin-top: 5px !important;
  color: var(--text-color) !important;
  margin-left: 87% !important;
  margin-bottom: 0px !important;
}

.minimize {
  color: var(--text-color) !important;
  cursor: pointer;
  margin-left: 93% !important;
  margin-bottom: 0px !important;
}

.fa-window-minimize {
  margin-left: 20px;
}

.fa-window-minimize:before {
  color: var(--blue-color);
}

.fa-window-maximize:before {
  color: var(--blue-color);
}

.no-data-label {
  padding-left: 50%;
  padding-top: 1%;
  padding-bottom: 1%;
  font-weight: 550;
  font-size: 18px;
}

/* pagination css starts */

.navigation {
  box-shadow: 0px 2px 17px rgb(0 0 0 / 15%) !important;
}

.custom-pagination {
  padding-left: 45% !important;
}

.ngx-pagination .current {
  background-color: var(--blue-color) !important;
}

/* pagination css ends */

/* mapbox css start */
#mapDiv {
  display: none;
}

a.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  display: none !important;
}

.mapboxgl-popup-content {
  background-color: transparent !important;
  box-shadow: none !important;
  font-size: 120% !important;
}

.mapboxgl-popup-close-button {
  display: none !important;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.map-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh-500);
  max-width: 100%;
  max-height: calc(100vh-500);
}

.marker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

#map-button {
  position: absolute;
  top: 15px;
  left: 15px;
  color: black !important;
  border-radius: 8px;
  background-color: white;
  border: none;
  box-shadow: 0px 6px 4px rgb(0 0 0 / 13%);
}

#largeMap {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: calc(100vh-100) !important;
}

#largeMapButton {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white !important;
  border-radius: 20px;
  font-weight: 1000;
  font-size: 150%;
  height: 35px;
  width: 35px;
  background-color: var(--blue-color);
  border: none;
}

#lg-map .load-os-list-map {
  padding: 5px;
  min-height: calc(100vh-100) !important;
  border-radius: 25px !important;
}

/* mapbox css ends */

.fa-paper-plane {
  color: rgb(44, 167, 42);
}

.fa-check {
  color: rgb(44, 167, 42);
}

.fa-times {
  color: #fa2b2b;
}

.bottom-icon {
  height: 50px;
  width: 50px;
  margin: 20px;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  background-color: var(--blue-color) !important;
  border: var(--blue-color) !important;
  border-radius: 8px !important;
  border: 2px solid var(--blue-color) !important;
  margin-bottom: 5px !important;
}

::ng-deep
  .multiselect-dropdown
  .dropdown-btn
  .selected-item-container
  .selected-item {
  background-color: var(--blue-color) !important;
  border: var(--blue-color) !important;
  border-radius: 8px !important;
}

.multiselect-dropdown .dropdown-btn {
  height: 42px;
  overflow-y: auto;
}

.multiselect-dropdown .dropdown-btn {
  font-size: initial !important;
  color: grey !important;
  border-radius: 10px !important;
  height: 42px;
}

*::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.no-shadow {
  box-shadow: none !important;
}

.chat-image {
  margin-top: 10px !important;
  max-width: 200px !important;
  max-height: 150px !important;
}

.load-os-dispatch-status-section {
  position: fixed;
  bottom: 0;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  width: 95%;
}

@media (max-width: 1190px) {
  .maximize-pane {
    position: relative;
    margin-top: 2%;
  }

  .load-os-dispatch-status-section {
    position: relative;
    width: 100%;
  }

  .load-os-list-map {
    height: 540px;
  }

  .load-os-dispatch-status-left-col {
    width: 100%;
  }

  .load-os-dispatch-status-right-col {
    width: 100%;
  }

  .maximize-pane {
    width: 100%;
  }

  .chat-body-footer .ico1 {
    padding-left: 40px;
  }

  .chat-body-footer .ico2 {
    padding-left: 0;
  }

  .chat-body-footer .ico3 {
    padding-left: 10px;
  }
}

td {
  word-break: break-word;
}

@media (max-width: 1190px) {
  .analytics-select-col .col {
    margin-bottom: 10px;
  }
}

@media (min-width: 1191px) {
  .maximize-pane {
    width: 416px;
  }

  .chat-body-footer .ico1 {
    padding-left: 0;
    padding-top: 2.5px;
  }

  .chat-body-footer .ico2 {
    padding-left: 0;
  }

  .chat-body-footer .ico3 {
    padding-left: 0;
    padding-top: 2.5px;
  }
}

thead tr:hover {
  background-color: var(--primary-color) !important;
}

.chat-white-link {
  color: white;
  text-decoration: underline;
}

.chat-white-link:hover {
  color: white;
  text-decoration: underline;
}

.chat-blue-link {
  color: var(--blue-color);
  text-decoration: underline;
}

.chat-blue-link:hover {
  color: var(--blue-color);
  text-decoration: underline;
}

body.swal2-toast-shown .swal2-container.swal2-center {
  width: auto !important;
}

.theme-green .bs-datepicker-body table td.week span {
  display: none;
}
/*ng-select single status drop  css start */
/* draft */
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: white transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}
.ng-select.ng-select-single .ng-select-container {
  height: 50px;
  border-radius: 10px;
  border: none;
}
.ld-st-drop .ng-select .ng-select-container {
  color: white;
}
.ld-st-drop span {
  color: white !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.draft .ng-select-container {
  background-color: var(--text-color) !important;
  padding-top: 0px;
  overflow: hidden;
}
.draft .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: white transparent transparent !important;
  border-style: solid;
  font-weight: 350;
  border-width: 4.5px 4.5px 2.5px;
}
.draft .ng-select.ng-select-single .ng-select-container {
  height: 50px;
}

.draft .ng-select.ng-select-opened .ng-select-container {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.draft .ng-select.ng-select-single .ng-select-container {
  height: 50px !important;
  border-radius: 10px !important;
  border: none !important;
}

.draft .ng-select .ng-select-container {
  color: white !important;
  height: 50px !important;
  border-radius: 10px;
  border: none;
  overflow: hidden;
  /* width: 100% !important; */
}
.draft
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-value {
  color: white !important;
  font-weight: 600;
  font-size: 1rem;
}

.draft .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: white transparent transparent;
  border-style: solid;
  font-weight: 350;
  border-width: 4.5px 4.5px 2.5px;
}

.draft .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: rgb(65, 134, 244);
  color: white;
}

.draft .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 2px 10px 2px 10px;
}
.assigned .ng-select-container {
  background-color: #2ca72a !important;
  padding-top: 0px;
  overflow: hidden;
}
.refused .ng-select-container {
  background-color: #ff2d2d !important;
  padding-top: 0px;
  overflow: hidden;
}
.pickedup .ng-select-container {
  background-color: #4186f4 !important;
  padding-top: 0px;
  overflow: hidden;
}
.cancelled .ng-select-container {
  background-color: #000000 !important;
  padding-top: 0px;
  overflow: hidden;
}
.assigned .ng-select.ng-select-opened .ng-select-container {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.draft .ng-select.ng-select-opened .ng-select-container {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.sngl-status .ng-select.ng-select-single .ng-select-container {
  height: 52px;
  border-radius: 10px;
  border: none;
}

.sngl-status .ng-select .ng-select-container {
  height: 52px;
  border-radius: 10px;
  border: none;
  overflow: hidden;
}
.sngl-status
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-value {
  color: #6f6d6d;
  font-weight: 600;
  font-size: 1rem;
}

.sngl-status .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--text-color) transparent transparent;
  border-style: solid;
  font-weight: 350;
  border-width: 4.5px 4.5px 2.5px;
}

.sngl-status
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background-color: rgb(65, 134, 244);
  color: white;
}

.sngl-status .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 2px 10px 2px 10px;
}

/* ng-select single status drop css end */

/* ng-select single select light  no border css start */

.sngl-light-noborder .ng-select .ng-select-container {
  background-color: var(--white-color) !important;
  padding-top: 0px;
  overflow: hidden;
}

.sngl-light-noborder .ng-select.ng-select-opened .ng-select-container {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.sngl-light-noborder .ng-select.ng-select-single .ng-select-container {
  height: 42px;
  border-radius: 10px;
  border: none;
}

.sngl-light-noborder .ng-select .ng-select-container {
  height: 42px;
  border-radius: 10px;
  border: none;
  overflow: hidden;
}
.sngl-light-noborder
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-value {
  color: #6f6d6d;
  font-weight: 600;
  font-size: 1rem;
}

.sngl-light-noborder .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--text-color) transparent transparent;
  border-style: solid;
  font-weight: 350;
  border-width: 4.5px 4.5px 2.5px;
}

.sngl-light-noborder
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background-color: rgb(65, 134, 244);
  color: white;
}

.sngl-light-noborder .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 2px 10px 2px 10px;
}
.sngl-light-noborder
  .ng-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  padding-top: 5px !important;
}
/* ng-select single select light no border css end */

/* ng-select single select shadow css start */

.sngl-shadow .ng-select .ng-select-container {
  background-color: var(--white-color) !important;
  padding-top: 0px;
  overflow: hidden;
  box-shadow: 0 6px 4px #00000021;
}

.sngl-shadow .ng-select.ng-select-opened .ng-select-container {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.sngl-shadow .ng-select.ng-select-single .ng-select-container {
  height: 42px;
  border-radius: 10px;
  border: none;
}

.sngl-shadow .ng-select .ng-select-container {
  height: 42px;
  border-radius: 10px;
  border: none;
  overflow: hidden;
}

.sngl-shadow
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-value {
  color: black;
  font-weight: 500;
  font-size: 15px;
}

.sngl-shadow .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--white-color) transparent transparent;
  border-style: solid;
  font-weight: 350;
  border-width: 4.5px 4.5px 2.5px;
}

.sngl-shadow
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background-color: rgb(65, 134, 244);
  color: white;
}

.sngl-shadow .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 2px 10px 2px 10px;
}

.sngl-shadow .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: black transparent transparent;
  border-style: solid;
  font-weight: 350;
  border-width: 4.5px 4.5px 2.5px;
}
.sngl-shadow
  .ng-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  padding-top: 5px !important;
}
/* ng-select single select shadow css end */
/* ng-select single select light css start */

.sngl-light .ng-select .ng-select-container {
  background-color: var(--white-color) !important;
  padding-top: 0px;
  overflow: hidden;
}

.sngl-light .ng-select.ng-select-opened .ng-select-container {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.sngl-light .ng-select.ng-select-single .ng-select-container {
  height: 42px;
  border-radius: 10px;
  border: 1.5px solid #a7a7a7;
}

.sngl-light .ng-select .ng-select-container {
  height: 42px;
  border-radius: 10px;
  border: 1.5px solid #a7a7a7;
  overflow: hidden;
}
.sngl-light
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-value {
  color: #6f6d6d;
  font-weight: 600;
  font-size: 1rem;
}

.sngl-light .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--text-color) transparent transparent;
  border-style: solid;
  font-weight: 350;
  border-width: 4.5px 4.5px 2.5px;
}

.sngl-light
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background-color: rgb(65, 134, 244);
  color: white;
}

.sngl-light .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 2px 10px 2px 10px;
}
.sngl-light
  .ng-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  padding-top: 5px !important;
}

/* ng-select single select light css end */
/* ng-select single select dark css start */
.sngl-drk .ng-select .ng-select-container {
  background-color: var(--primary-color) !important;
  padding-top: 0px;
  overflow: hidden;
}

.sngl-drk .ng-select.ng-select-opened .ng-select-container {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.sngl-drk .ng-select.ng-select-single .ng-select-container {
  height: 42px;
  border-radius: 10px;
  border: 1.5px solid var(--primary-color);
}

.sngl-drk .ng-select .ng-select-container {
  height: 42px;
  border-radius: 10px;
  border: 1.5px solid #a7a7a7;
  overflow: hidden;
}

/* .sngl-drk .ng-select .ng-arrow-wrapper {
  padding-right: 0px;
  padding-top: 18px;
} */
.sngl-drk
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-value {
  color: #6f6d6d;
  font-weight: 600;
  font-size: 1rem;
}

.sngl-drk .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--text-color) transparent transparent;
  border-style: solid;
  font-weight: 350;
  border-width: 4.5px 4.5px 2.5px;
}

.sngl-drk
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background-color: rgb(65, 134, 244);
  color: white;
}

.sngl-drk .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 2px 10px 2px 10px;
}
.sngl-drk
  .ng-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  padding-top: 4px !important;
}

.ng-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  color: #767676 !important;
  font-size: 115%;
  margin-top: 2px;
  /* font-weight: 500 !important; */
}
::ng-deep
  .ng-select.ng-select-single.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  color: black !important;
}
.ng-select .ng-clear-wrapper {
  display: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 105%;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: var(--blue-color);
  color: white;
}
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected:hover {
  background-color: var(--blue-color);
  color: white;
}

/* ng-select single select  css ends */

/* ng-select multiple select dark  css start */
.multi-drk .ng-select .ng-select-container {
  height: 42px;
  border-radius: 10px;
  border: 1.5px solid var(--primary-color);
  overflow: auto !important;
  /* padding-top: 12px; */
  background-color: var(--primary-color) !important;
}
.ng-select.ng-select-searchable
  .ng-select-container
  .ng-value-container
  .ng-input {
  opacity: none;
}
::ng-deep
  .ng-select.ng-select-multiple.ng-select-disabled
  > .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-label {
  color: red !important;
}
.multi-drk
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
}

.multi-drk
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: var(--blue-color);
  border-radius: 8px;
}
.multi-drk
  .ng-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  padding-top: 5px !important;
}
.multi-drk
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  color: #767676 !important;
  font-size: 115%;
  margin-top: 2px;
  /* font-weight: 500 !important; */
}

.multi-drk .ng-select .ng-arrow-wrapper {
  padding-right: 0px;
  /* padding-bottom: 18px; */
}

.multi-drk .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--text-color) transparent transparent;
  border-style: solid;
  font-weight: 350;
  border-width: 4.5px 4.5px 2.5px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  /* background-color: var(--blue-color); */
  background-color: rgb(65, 134, 244);
  color: white;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 2px 10px 2px 10px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  background-color: var(--blue-color);
  border-radius: 8px;
}

/* multi light css start */
.multi-light .ng-select .ng-select-container {
  height: 42px;
  border-radius: 10px;
  border: 1.5px solid #a7a7a7;
  overflow: auto !important;
  /* padding-top: 12px; */
  background-color: var(--white-color) !important;
}

.multi-light
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
}

.multi-light
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: var(--blue-color);
  border-radius: 8px;
}
.multi-light
  .ng-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  padding-top: 5px !important;
}

.multi-light
  .ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  color: #767676 !important;
  font-size: 115%;
  margin-top: 2px;
}

.multi-light .ng-select .ng-arrow-wrapper {
  padding-right: 0px;
  /* padding-bottom: 18px; */
}

.multi-light .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--text-color) transparent transparent;
  border-style: solid;
  font-weight: 350;
  border-width: 4.5px 4.5px 2.5px;
}

/* multi light css end */
.ng-dropdown-panel.ng-select-bottom {
  max-height: 100px;
  overflow-y: auto;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

::ng-deep option:hover {
  background-color: var(--blue-color) !important;
  color: white !important;
  cursor: pointer;
}

::ng-deep select:focus > option:hover {
  background-color: var(--blue-color) !important;
  color: white !important;
  cursor: pointer;
}

::ng-deep select option:hover {
  background-color: var(--blue-color) !important;
  color: white !important;
  cursor: pointer;
}

/* ng-select multiple select dark  css ends */

::ng-deep .overlay {
  top: 76px !important;
}

.stp-cnt {
  width: 55px;
  min-width: 55px;
}

.new-assets .adChat .modal-title {
  padding-right: 17% !important;
}

/* Calender CSS start */
.theme-green .bs-datepicker-body table td span.selected {
  background-color: var(--blue-color) !important;
}

.theme-green .bs-datepicker-head {
  background-color: var(--blue-color) !important;
}

.bs-datepicker {
  border-radius: 10px;
}

/* Calender CSS end*/

/* address custom dropdown start */
.add-item {
  border-bottom: 1px solid var(--primary-color);
  padding: 5px;
}

.add-item:hover {
  background-color: var(--blue-color);
  color: white;
}

.add-item-no {
  padding: 5px;
}

.addUl .form-control:hover,
.addUl .form-control:focus {
  border-color: transparent;
}

.addUl .form-input {
  box-shadow: none;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.addUl .form-input:hover {
  box-shadow: none;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

/* address custom dropdown end */
.analytics-select-col .create-load-btn {
  width: 100% !important;
}

.date ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text-color) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.date :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--text-color) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.date ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text-color) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.autocomplete-container .suggestions-container.is-visible {
  position: relative;
}

.loc-plc ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(109, 106, 106) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.loc-plc :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--text-color) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.loc-plc ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text-color) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}
input[name="ftl"] {
  accent-color: var(--blue-color) !important;
}
input[type="radio"] {
  accent-color: var(--blue-color) !important;
}
input[name="dfcfs"] {
  accent-color: var(--blue-color) !important;
}
/* New Load Collapsed  start */
.load-form .accordion-button:not(.collapsed) {
  color: black;
}
.load-form .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/images/icons/downarrow.svg");
}
.load-form .accordion-item {
  border: 2px solid rgba(0, 0, 0, 0.125);
}
.load-form .accordion-button {
  background-color: #ebebeb;
}
.load-form .accordion-body {
  background-color: #ebebeb;
  padding-top: 0px;
}
.load-form .form-input {
}
/* New Load Collapsed  end */
.cust-form .form-label {
  margin-top: 7px;
}
.load-os-list-map .load-itinerary-box {
  margin-left: 10px;
  margin-top: 10px;
}
/* .chat-body-content .accordion-button:after {
  display: none;
} */
.inner-card {
  background-color: rgb(239, 239, 239);
}

.dash-tbl .accordion-table-record {
  padding-left: 0px !important;
  border: 2px solid var(--primary-color);
}
.cc-code {
  font-size: 14px !important;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

/* disable ng-select start*/
.ng-select.disable-arrow .ng-arrow-wrapper .ng-arrow {
  display: none;
}
.ng-select.disable-dropdown .ng-dropdown-panel {
  display: none;
}
.ng-select.disable-clear-all .ng-clear-wrapper {
  display: none;
}
.ng-select.ng-select-opened.disable-drop .ng-select-container {
  border-radius: 10px !important;
}
.form-control:disabled {
  background-color: var(--primary-color);
}
/* disable ng-select end*/

.password-container {
  position: relative;
}
.password-container .fa-eye {
  position: absolute;
  top: 60%;
  color: grey;
  right: 5%;
  cursor: pointer;
  font-size: large;
}
.password-container .fa-eye-slash {
  position: absolute;
  top: 60%;
  color: grey;
  right: 5%;
  cursor: pointer;
  font-size: large;
}

/* range slider start */

.ngx-slider .ngx-slider-bubble.ngx-slider-limit {
  display: none;
}
.ngx-slider .ngx-slider-pointer {
  cursor: pointer;
  width: 22px;
  height: 22px;
  top: -10px;
  background-color: #0db9f0;
  z-index: 3;
  border-radius: 16px;
}

.ngx-slider .ngx-slider-pointer:after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  top: 9px;
  left: 9px;
  border-radius: 10px;
  background: #fff;
}

.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-12 {
  font-size: 12px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fs-26 {
  font-size: 26px;
}
.fs-28 {
  font-size: 28px;
}
.fs-30 {
  font-size: 30px;
}

.book-now {
  background-color: #05b22b;
  font-size: 18px;
  color: #ffffff;
  font-weight: 300;
  padding: 10px 20px;
  border-radius: 10px;
}

.gap-10 {
  gap: 6rem !important;
}
.gap-8 {
  gap: 5rem !important;
}
.fw-600 {
  font-weight: 600;
}

/* range slider end */
